import React, { useState, useEffect } from 'react';
import './App.css';
import {thousandCommas} from './util.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col, Table, Dropdown, Navbar,
    Button, OverlayTrigger, Popover } from 'react-bootstrap'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import {CaretDownFill} from 'react-bootstrap-icons'
import Papa from 'papaparse';
import dayjs from 'dayjs';
import Loader from 'react-loader-spinner'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Range } from 'react-range';
import { ReactComponent as ProjectIcon } from './beauty.svg';


import ReactGA from 'react-ga';
ReactGA.initialize('UA-168223701-1');
ReactGA.pageview(window.location.pathname + window.location.hash);

// Require icons and store in object
// const iconReq = iconFile => require(`../node_modules/bootstrap-icons/icons/${iconFile}.svg`)
// const iconArr = [
//     "caret-down",
//     "caret-up"
// ]
// const icons = Object.assign(...iconArr.map(iconName => ({[iconName]: iconReq(iconName)})))

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const paramStr = (query, isPath) => {
    const encoder = (k, v) => isPath
        ? encodeURIComponent(`${k}=${v.replace(" ", "+")}`)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
    return Object.keys(query).sort()
        .map((k) => encoder(k, query[k]))
        .join(isPath ? '/' : '&')
}

const decodeCsv = (resp, resolve) => {
    resp.body.getReader().read().then(result => {
        const decoder = new TextDecoder('utf-8')
        const csv = decoder.decode(result.value) // the csv text
        console.log(csv)
        const parsed = Papa.parse(csv, { header: true }) // object with { data, errors, meta }
        resolve(parsed);
    })
}

const s3BucketUrl = "https://zjup-cov-data.s3.amazonaws.com"

const densityOptions = [
  { value: 'any', label: "Any Density"},
  { value: '0-10', label: 'Light Rural (0-10)' },
  { value: '10-100', label: 'Rural (10-100)' },
  { value: '100-1000', label: 'Suburban (100-1K)' },
  { value: '1000-', label: 'Urban (1K+)' }
  // { value: '5000-', label: "Dense Urban (5K+)"}
]

function getDataFile(query) {
    const todayDate = dayjs.utc().format("YYYY-MM-DD")
    const s3DataUrl = `${s3BucketUrl}/query-results/${todayDate}/${paramStr(query, true)}/data.csv`;
    console.log(s3DataUrl)
//    const s3DataUrl = "https://zjup-cov-data.s3.amazonaws.com/query-results/2020-05-12/orderby%3Dcounty/state%3DCalifornia/data.csv"
    const apiBaseUrl = "https://6nst1w8ydf.execute-api.us-east-1.amazonaws.com/dev/?";
    const apiDataUrl = apiBaseUrl + paramStr(query, false);

    return new Promise((resolve, reject) => {
        try {
            fetch(s3DataUrl).then(resp => {
                if (resp.status === 200) {
                    decodeCsv(resp, resolve)
                } else {
                    fetch(apiDataUrl).then(output => {
                        console.log("FETCHNG API " + apiDataUrl)
                        if (output.status === 200) {
                            fetch(s3DataUrl, {cache: "no-store"}).then(resp => {
                                if (resp.status === 200) {
                                    decodeCsv(resp, resolve)
                                } else {
                                    reject("Something went wrong.")
                                }
                            })
                        } else {
                            reject("API responded with bad code.")
                        }
                    })
                }
            })
        } catch (e) {
            reject("An error occurred calling the data file creation service.");
        }
    });
}
function getMetadata() {
  return new Promise((resolve, reject) => {
    fetch(s3BucketUrl + "/nyt_metadata.json").then(resp => {
      console.log(resp)
      resp.json().then(json => {
        console.log(json)
        resolve(json)
      })
    })
  })
}

const format = {
  state: ["text", "State"],
  county: ["text", "County"],
  pop: ["int", "Population"],
  "pop-density": ["dec1", "Pop. Density"],
  "land-area-sqmi": ["int", "Land Area"],
  cases: ["int", "Total Cases"],
  cases_r: ["dec1", "Total Case Rate"],
  cases_d1: ["int", "New Cases Today"],
  cases_d1r: ["dec1", "New Case Rate Today"],
  cases_d5r: ["dec1", "Case Rate (5-day)"],
  cases_d10r: ["dec1", "Case Rate (10-day)"],
  cases_10: ['int', "Cases as of 10 days ago"],
  cases_d5: ['int', "New cases (5-day)"],
  cases_d10: ['int', "New cases (10-day)"],
  deaths: ["int", "Total Deaths"],
  deaths_r: ["dec1", "Total Death Rate"],
  deaths_d1: ["int", "Deaths Today"],
  deaths_d1r: ["dec1", "Daily Death Rate"],
  deaths_d5r: ["dec1", "Death Rate (5-day)"],
  deaths_d10r: ["dec1", "Death Rate (10-day)"],
}

const Cell = ({row, col, orderby, update=(_=>_), header=false}) => {
  
  const sort_column = orderby.replace(/_desc|_asc/,"") === col ? "sort-column" : ""
  const cellFormat = {
    text: <td className={sort_column}>{row[col]}</td>,
    int: <td className={`align-right ${sort_column}`}>{thousandCommas(row[col], 0)}</td>,
    dec1: <td className={`align-right ${sort_column}`}>{thousandCommas(row[col], 1)}</td>,
    header: <th className={sort_column}>{format[col][1]}
    <a href={`#/${col}/`}
      onClick={() => {
        update(col)
        }}
    >
    <CaretDownFill className="sort-caret" size="10"/> 
    </a>
    </th>
  }
  return ( header 
    ? cellFormat['header']
    : cellFormat[format[col][0]])
}
function decimalAbbreviation(num, digits) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "K" },
    { value: 1E6, symbol: "M" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

const populationRange = (logRange, setLogRange) => (
  <Popover id="popover-basic">
    <Popover.Content>
        <div className="range-spacer"></div>
      <Range
        step={0.05}
        min={3.5}
        max={7.1}
        values={logRange}
        onChange={values => setLogRange(values)}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '3px',
              width: '100%',
              backgroundColor: '#ccc'
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '1em',
              width: '1em',
              backgroundColor: '#999',
              borderRadius: '0.3em'
            }}
          />
        )}
      />
    </Popover.Content>
  </Popover>
)
const uiTimeouts = {}  // for adding delay to UI actions

function App() {
  const [tData, setTData] = useState({data:[]});
  const [metadata, setMetadata] = useState({metadata:{
    date_max:dayjs().add(-2, 'day').format("YYYY-MM-DD"),
    date_min:dayjs("2020-02-20").format("YYYY-MM-DD")
  }})
  const [query, setQuery] = useState({
    orderby:"cases_d5r_desc", 
    datetime: dayjs().add(-2, 'day').format("YYYY-MM-DD")
  })
  const [loading, setLoading] = useState({loading: true})
  const [popRange, setPopRange] = useState([5000, 12*1000*1000])
  const [densityRange, setDensityRange] = useState(null)

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    console.log("Effect: metadata")
    getMetadata().then(ob => {
      ob.states = [...(new Set(ob.counties.map(x => x[0])))]
      setMetadata(ob)
      const newquery = {...query,
        datetime: ob.date_max
      }
      console.log("SETTING QUERY")
      console.log(newquery)
      setQuery(newquery)
      })

  }, []);
  useEffect(() => {
    console.log("Effect: query")
    console.log(query)
    setLoading(true)
      getDataFile(query).then(output => {
        console.log("UseEffect")
        console.log(output)
        setTData(output)
        setLoading(false)
      })
  }, [query])

  const updateSort = (column) => {
    const sortOrder = format[column][0] === "text"
        ? ""
        : "_desc"
    const newQuery = {...query,
        orderby: (column + sortOrder)
    }
    setQuery(newQuery)
  }
  useEffect(() => {
    console.log("Effect: popRange")
    const newQuery = {...query}
    const popRangeQuery = [null, null]
    popRangeQuery[0] = popRange[0] <= 5000 ? null : popRange[0]
    popRangeQuery[1] = popRange[1] >= 11*1000*1000 ? null : popRange[1]
    if (popRangeQuery.join("-") === "-")
      delete newQuery.popRange
    else 
      newQuery.popRange = popRangeQuery.join("-")
      clearInterval(uiTimeouts.popRange)
      uiTimeouts.popRange = setTimeout(
        () => {
          setQuery(newQuery)
          console.log("actually sending poprange query")
        },
        2500
      )
    
    
  },[popRange])

  const tableRows = [
    "county", "state",
    "cases", "cases_r", "cases_d5r", "cases_d10r",
    "deaths", "deaths_r", "deaths_d5r", "deaths_d10r",
    "pop", "pop-density"
  ]
  return (
  <div>
<Navbar expand="lg" bg="dark" variant="dark">
<Container>
  <Navbar.Brand bg="dark" href="#home">
    <ProjectIcon className="project-icon" />
    Covid-19 Analysis
  </Navbar.Brand>
  <Nav.Link href="https://twitter.com/HealthViz" className="nav-link">Questions or comments?</Nav.Link>
</Container>
</Navbar>
<Container>
<h1>Coronavirus U.S. County Analysis</h1>
<h3>Cumulative data and hotspots</h3>
<p>The table below shows U.S. county data on Covid-19 confirmed cases (infections) and deaths.
  <ul>
    <li>
    "Rate" refers to the number of cases or deaths per 100,000 people. 
    </li>
    <li>
    "Total rate" means the number per 100K population to date.
    </li>
    <li>
    "5-day" and "10-day" refer to the daily average for that period to the date in question.
    </li>
  </ul>
</p>
</Container>
<div className="table-container">
<Row>
<Col>
<Row className="table-control">
<Col className="table-control-col col-sm-6 col-lg">
<Dropdown>
  <Dropdown.Toggle className="control-sort">
    <span>Sort: {query.orderby
        ? format[query.orderby.replace("_desc","")][1]
        : "Case Rate (5-day)"}</span>
  </Dropdown.Toggle>
  <Dropdown.Menu>
    {Object.keys(format).map( col => (
      <Dropdown.Item 
        href={`#/${col}`}
        onSelect={(k, e) => {
          console.log("HI" + col)
          setQuery({...query,
            orderby: (col + (format[col][0] === "text" ? "" : "_desc"))
          })}
        }
      >{format[col][1]}</Dropdown.Item>
    ))}
  </Dropdown.Menu>
</Dropdown>
</Col>
<Col className="table-control-col col-sm-6 col-lg">
<Dropdown>
  <Dropdown.Toggle className="control-us-state">
    <span>{query.state ? query.state: "States: All"}</span>
  </Dropdown.Toggle>
  <Dropdown.Menu>
    {metadata.states
     && ["All", ...metadata.states].map( state => (
      <Dropdown.Item 
        href={`#/${state}`}
        onSelect={(k, e) => {
          console.log("HI" + state)
          const newquery = {...query}
          if (state === "All") {
            delete newquery.state
          } else {
            newquery.state = state
          }
          setQuery(newquery)}
        }
      >{state}</Dropdown.Item>
    ))
  }
  </Dropdown.Menu>
</Dropdown>
</Col>
<Col className="table-control-col col-sm-6 col-lg">
    <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={populationRange(
            popRange.map(x => Math.log10(x)),
            (range)=>{
                range = range.map(x => Math.pow(10, x))
                range = range.map(x => (
//                    x > 1000 * 1000
//                    ? Math.round(x/1000/1000) * 1000 * 1000
                    x > 100*1000
                        ? Math.round(x/100/1000) * 100*1000
                        : x > 10*1000
                            ? Math.round(x/10/1000) * 10*1000
                            : x
                ))
                setPopRange(range)
                console.log(`Range ${range}`)
              }
            )}
    >
        <Button >
          {popRange[0] <= 5000 && popRange[1] >= 12000000
            ? "Population" 
            : `Pop: ${popRange.map(n => decimalAbbreviation(n)).join("-")}`}
        <CaretDownFill className="pop-range" size="10" />
        </Button>
    </OverlayTrigger>
</Col>
<Col className="table-control-col pop col-sm-6 col-lg">
<Dropdown>
  <Dropdown.Toggle className="control-density">
    <span>{densityRange ? densityOptions.find(a => a.value === densityRange).label: "Population Density"}</span>
  </Dropdown.Toggle>
  <Dropdown.Menu>
    {densityOptions.map( opt => (
      <Dropdown.Item 
        href={`#/${opt.value}`}
        onSelect={(k, e) => {
          console.log("Hi" + opt.label)
          setDensityRange(opt.value)
          const newquery = {...query}
          if (opt.value === "any") {
            delete newquery.densityRange
          } else {
            newquery.densityRange = opt.value
          }
          setQuery(newquery)
        }}
      >{opt.label}</Dropdown.Item>
    ))}
  </Dropdown.Menu>
</Dropdown>

{/* <Select
  placeholder="Pop. Density"
    options={densityOptions}
    onChange={(o) => {
      setDensityRange(o.value)
      console.log("HI" + densityRange)
      
    }/> */}
</Col>
<Col className="table-control-col data-date col-sm-12 col-lg">
<div className="button data-date">
<span className="table-control data-date">Data as of: </span>
<div className="data-date-input">
    <DayPickerInput
    format={"yyyy-mm-dd"}
    value={query.datetime}
    dayPickerProps={{
        disabledDays: {
            after: new Date(new Date(metadata.date_max).getTime() + 86400000),
            before: new Date(metadata.date_min)
        },
        placement: "right"
    }}
    min={metadata.date_min}
    max={metadata.date_max}
    onDayChange={(day) => {
      const fmtDay = dayjs(day).format('YYYY-MM-DD')
      console.log(fmtDay)
      const newquery = {...query}
      newquery.datetime = fmtDay
      setQuery(newquery)
    }}
  />
  <CaretDownFill className="data-date" size="10" />
</div>
</div>
</Col>
</Row>
<div className="data-table">
<div className={`loader ${loading ? "loading" : ""}`}>
<Loader
    type="Oval"
    color="#0088EE"
    height="100"
    width="100"
    />
</div>
<Table striped bordered hover size="sm">
  <thead>
    <tr>
        <th>Rank</th>
      {tableRows.map(column =>
        <Cell
            row={{}}
            col={column}
            orderby={query.orderby}
            update={updateSort}
            header={true}
        />)}
    </tr>
  </thead>
  <tbody>
    {tData.data.map((x, i) => (
        <tr>
          <td>{i+1}</td>
          {tableRows.map(column =>
            <Cell
                row={x}
                col={column}
                orderby={query.orderby}
                header={false}
            />)}
        </tr>
    ))}
  </tbody>
</Table>
</div>
</Col>
</Row>
</div>
<footer>
<Container>
  <Row><Col>
  <div>
  <p>
    Covid-19 cases and deaths data provided by <a href="https://github.com/nytimes/covid-19-data">The New York Times Covid-19 data repository</a>.
  </p>
  <p>Data on this site are provided without warranty and for informational purposes only.
    Please consult national, state and local health authorities for the latest information on health risks
  from Covid-19 and recommended practices for staying safe and healthy.
  </p>
  </div>
  <div>
    Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> 
  </div>
  </Col></Row>
</Container>
</footer>
</div>
  );
}

export default App;
